<template>
  <div>
    <b-sidebar
      id="sidebar-term"
      aria-labelledby="sidebar-cat-header"
      ref="termSidebar"
      backdrop
      shadow
      right
      :title="$t('branchDetail')"
      v-model="isShowModal"
    >
      <div class="px-3 py-2">
        <AddressDetailPanel
          :address="address"
          :activeLanguage="activeLanguage"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AddressDetailPanel from "@/components/service-location/AddressDetailPanel";
export default {
  components: {
    AddressDetailPanel
  },
  props: {
    address: {
      required: true,
      type: Object
    },
    activeLanguage: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isShowModal: false
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
</style>