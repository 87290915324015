var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"mapRef",staticStyle:{"width":"auto","height":"calc(100vh - 176px)"},attrs:{"center":_vm.center,"zoom":15,"map-type-id":"terrain","options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    disableDefaultUi: false
  }}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"icon":_vm.getIcon(index, m),"position":m.position,"clickable":true},on:{"click":function($event){return _vm.openWindow(m.position, m)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }